<template>
	<div>
		<div class="header " :class="{ filter: is_scroll }">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ program.title }}</h2>
						<span class="page_subtit">{{ item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
			<!-- 멤버검색 상단 -->
			<div
				v-if="is_scroll"
				class="serch_top"
			>
				<div class="top top_wh">
					<div class="header_search">
						<input
							v-model="item_search.search_value"
							type="text" :placeholder="$language.common.search_member"
							@keyup.enter="getSearch(1)"
						>
					</div>
					<div class="header_search_btn">
						<div
							v-if="item_search.search_value"
							class="btn_del"
						>
							<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
							<button
								@click="item_search.search_value = ''"
								class="active"
							><span class="hide">{{ $language.common.close }}</span></button>
						</div>
						<div class="util">
							<button
								@click="getSearch(1)"
								class="img_button" type="submit" name="click"
							><span class="hide">{{ $language.common.search }}</span></button>
						</div>
					</div>
				</div>
			</div>
			<!-- //멤버검색 상단 -->
		</div>
		<!-- //고정해더 -->
		<div
			class="section_wrap"
			:class="is_scroll ? 'pt-120' : 'pt-70'"
			ref="post_list_item_view"
		>
			<div class="subindex_head">
			<div
				class="underboss pt-30"
			>
				<div class="container">
					<div class="row">
						<h2 class="itemlist_head col_primary">{{ $language.cartel.current_under_boss }}</h2>
						<template
							v-if="items_manager.length > 0"
						>
						<div
							v-for="(item_under_boss, index) in items_manager"
							:key="'item_' + index"
							class="underboss_area thumbnail_list ct_invite"
							style="margin-bottom: 20px"
						>
							<div class="ct_invite_list">
								<div class="circle_thumbnail_item">
									<div class="clear">
										<div class="picture">
											<img
												v-if="item_under_boss.admin_profle_nft_card_img_url"
												:src="$request.upload_url(item_under_boss.admin_profle_nft_card_img_url)" :alt="$language.cartel.recommend_cartel"
											>
											<img
												v-else
												:src="require('@/assets/image/@nophoto.png')" :alt="$language.cartel.need_appointment_under_boss"
											>
										</div>
										<div class="text_area">
											<strong
												class="underboss_name"
											>{{ item_under_boss.admin_nickname }}</strong>
										</div>
									</div>
									<div class="ct_invite_btn under_btn">
										<div>
											<button
												@click="onPermission(item_under_boss)"
												class="btn_outline_blue btn_s"
											>{{ $language.common.authority }}</button>
											<button
												@click="onConfirm(item_under_boss, 'fire')"
												class="btn_outline_blue btn_s"
											>{{ $language.common.dismissal }}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						</template>
						<div
							v-else
							class="underboss_area thumbnail_list ct_invite"
						>
							<div class="ct_invite_list">
								<div class="circle_thumbnail_item">
									<div class="clear">
										<div class="picture">
											<img
												:src="require('@/assets/image/@nophoto.png')" :alt="$language.cartel.need_appointment_under_boss"
											>
										</div>
										<div class="text_area">
											<strong
												class="underboss_name"
											>{{ $language.cartel.need_appointment_under_boss_02 }}</strong>
										</div>
									</div>
									<div class="ct_invite_btn under_btn">
										<div>
											<button
												class="btn_outline_blue btn_s"
												disabled="true"
											>{{ $language.common.authority }}</button>
											<button
												disabled="true"
												class="btn_outline_blue btn_s"
											>{{ $language.common.dismissal }}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>

			<!-- 언더보스 조건에 충족된 멤버 -->
			<div class="subindex_item">
			<div class="underboss_member pt-30">
				<div class="container">
					<div class="row">
						<ul
							class="underboss_member_area thumbnail_list ct_invite"
						>
							<h2 class="itemlist_head col_primary">{{ $language.cartel.be_met_criteria_member_under_boss }}</h2>
							<template
								v-if="items_member.length > 0"
							>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@nophoto.png')" :alt="$language.cartel.recommend_cartel">
											</div>
											<div class="text_area">
												<strong>{{  item.nickname }}</strong>
												<p class="textzone">
													{{  item.cartl_member_grade_name }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn">
											<button
												@click="onConfirm(item, 'confirm')"
												class="btn_outline_blue btn_s"
												:disabled="item.is_boss"
											>{{  $language.common.appointment }}</button>
										</div>
									</div>
								</div>
							</li>
							</template>
							<div
								v-else
								class="intro_content" style="display: flex; flex-direction: column; align-items: center; height: 100%; padding-top: 50%"
							>
								<div class="none_top character3_none">
									<div class="text_guide">
										<span class="cont_none" style="display: block; text-align: center">{{ none_text }}</span>
									</div>
								</div>
							</div>
						</ul>

					</div>
				</div>
			</div>
			</div>
			<!-- //언더보스 조건에 충족된 멤버 -->
		</div>

		<Popup_confirm
			v-if="is_confirm"
			@click="postConfirm"
			@cancel="cancelConfirm"
		>
			<template v-slot:title>{{  $language.common.under_boss }} {{ title_text }}</template>
			<template
				v-slot:main-txt
			><!--아래 멤버를 언더보스로 {{ title_text }}하시겠습니까?--> {{  $language.cartel.fellow_appointment_under_boss }}</template>
			<template
				v-slot:sub-txt
			>{{ item_user.nick }}</template>

		</Popup_confirm>

		<mafia089
			v-if="is_089"
			:user="user"
			:member="item_user"
			:cartel="item_cartel"

			@click="cancelPermission"
			@cancel="cancelPermission"

			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: white; z-index: 9999"
		></mafia089>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>
	</div>
</template>

<script>

	import mafia089 from "@/view/Cartel/mafia089"
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import ScrollPagination from "@/components/ScrollPagination";
	export default {

		name: 'mafia084'
		, components: {ScrollPagination, Popup_confirm, mafia089}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.cartel.manage_under_boss
          , title: this.$language.cartel.manage_under_boss
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, is_scroll: true
				, scrollTop: 0
				, item_search: {
					search_value: ''
					, page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				}
				, item_cartel: {}
				, items_member: []
				, is_search: false
				, is_confirm: false
				, item_user: null
				, items_manager: []
				, confirm_type: null
				, is_089: false
				, items_board_config: []
				, max: false
				, is_add: true
			}
		}
		, computed: {
			none_text: function(){
				let t = this.$language.common.error_empty_search_value

				if(this.is_search && this.items_member.length == 0){
					t = this.$language.common.no_search_data
				}

				return t
			}
			, title_text: function(){
				let t = this.$language.common.appointment
        if(this.confirm_type == 'fire'){
					t = this.$language.common.dismissal
				}

				return t
			}

		}
		, methods:{

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getMember: async function(){
				try{
					this.is_add = false
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_member_search
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){

						this.items_member = this.items_member.concat(result.data.cartl_member_list)

						if(result.data.cartl_member_list.length > 0){
							this.max = false
						}else{
							this.max = true
						}

						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_add = true
					this.$bus.$emit('on', false)
				}
			}
			, onPermission: function(user){
				this.item_user = user
				this.is_089 = true
			}
			, cancelPermission: function(){
				this.is_089 = false
				this.item_user = null
			}
			, postCartelFire: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_fire
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.item_user.admin_member_number
						}
						, type: true
					})
					if(result.success){
						await this.getManager()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
			, getSearch: function(page = 1){
				this.item_search.page_number = page
				this.getManager()
			}
			, getManager: async function(){
				try{
					this.is_add = false
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_manager
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.items_manager = result.data.current_admin_list ? result.data.current_admin_list : []

						if(this.item_search.page_number == 1){
							this.items_member = result.data.operating_targeter_list
						}else{
							this.items_member = this.items_member.concat(result.data.operating_targeter_list)
						}
						if(result.data.operating_targeter_list.length > 0){
							this.max =false
						}else{
							this.max = true
						}

						console.log(this.items_member)

					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, postConfirm: function(){
				switch (this.confirm_type){
					case 'confirm':
						this.postAppoint()
						break
					case 'fire':
						this.postCartelFire()
						break
					default:
						this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_request})
						break
				}
			}
			, cancelConfirm: function(){
				this.is_confirm = false;
			}
			, postAppoint: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_appoint
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.item_user.member_number
						}
						, type: true
					})

					if(result.success){
						this.getSearch(1)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
			, onConfirm: function(user, type){
				this.item_user = user
				this.confirm_type = type
				this.is_confirm = true
			}
			, postCartelPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_permission
						, data: {
							cartel_id: this.$route.params.idx
							, user: this.item_user
						}
						, name: 'postCartelPermission'
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_089 = false
				}
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getData()
			await this.getManager()

			let self = this
			document.body.addEventListener('scroll', function(){
				self.scrollTop = document.body.scrollTop
				let max = 162

				if(Number(document.body.scrollTop) >= max){
					self.is_scroll = true
				}else{
					self.is_scroll = true
				}
			});
		}
	}

</script>