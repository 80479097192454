<template>
	<div v-if="is_view">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('cancel')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ program.title }}</h2>
						<span class="page_subtit">{{ cartel.cartl_name }} > {{ member.admin_nickname }}</span>
					</div>
					<div class="save">
						<button
							@click="postPermission"
							class="btn_save"
						>{{  $language.common.ok }} </button>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-70">
			<div class="subindex_wrap">
				<!-- 모든권한 -->
				<div class="subindex_head pt-10">
					<div class="container">
						<div class="subindex_box_2">
							<div class="bright_checks">
								<input
									v-model="is_all"
									type="checkbox" id="all"
									@click="setPermission"
								>
								<label for="all" class="all_check">{{  $language.auth.can_all_authority }} </label>
							</div>
						</div>
					</div>
				</div>
				<!-- //모든권한 -->
				<!-- 카르텔관리 -->
				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_2">
							<h2 class="itemlist_head col_primary">{{  $language.cartel.manage_cartel }} ({{  $language.auth.all_approval_stop_authority }})</h2>
							<ul class="settings_list">
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_admin_mangement']"
											type="checkbox" id="ct1"
											@change="isAll(member.permission['cartl_admin_mangement'])"
										>
										<label for="ct1">{{  $language.cartel.manage_under_boss }} ({{  $language.cartel.appointment_and_dismissal }}) </label>
									</div>
								</li>
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_member_mangement']"
											value="CA01700002"
											type="checkbox" id="ct2"
											@change="isAll(member.permission['cartl_member_mangement'])"
										>
										<label for="ct2">{{  $language.common.manage_member }} ({{  $language.auth.all_approval_stop }})</label>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- //카르텔관리 -->
				<!-- 등록관리 -->
				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_2">
							<h2 class="itemlist_head col_primary">{{  $language.common.manage_register }}</h2>
							<ul class="settings_list">
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_notice_mttrs_reg']"
											type="checkbox" id="ct3"
											@change="isAll(member.permission['cartl_notice_mttrs_reg'])"
										>
										<label for="ct3">{{  $language.common.notice }}</label>
									</div>
								</li>
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_board_creation']"
											type="checkbox" id="ct4"
											@change="isAll(member.permission['cartl_board_creation'])"
										>
										<label for="ct4">{{  $language.notice.create }}</label>
									</div>
								</li>
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_subscrp_board_creation']"
											type="checkbox" id="ct5"
											@change="isAll(member.permission['cartl_subscrp_board_creation'])"
										>
										<label for="ct5">{{  $language.notice.create2 }}</label>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- //등록관리 -->


				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_2">
							<h2 class="itemlist_head col_primary">{{  $language.common.manage_grade }}</h2>
							<ul class="settings_list">
								<li class="settings_list_item listcheck_item">
									<div class="bright_checks">
										<input
											v-model="member.permission['cartl_member_grade_mangement']"
											type="checkbox" id="ct13"
											@change="isAll(member.permission['cartl_member_grade_mangement'])"
										>
										<label for="ct13">{{  $language.common.manage_grade_txt }}</label>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<!-- 게시판관리 -->
				<div class="subindex_item hide">
					<div class="container">
						<div class="subindex_box_2">
							<h2 class="itemlist_head col_primary">{{  $language.notice.manage }} ({{  $language.notice.article_delete_authority }})</h2>
							<ul class="settings_list">
								<li
									v-for="(item, index) in items_board_config"
									:key="'item_' + index"
									class="settings_list_item listcheck_item"
								>
									<div class="bright_checks">
										<input
											v-model="member.permission[item.board_number]"
											type="checkbox" :id="'board_' + item.board_number"
											@click="isAll(member.permission[item.board_number])"
										>
										<label :for="'board_' + item.board_number">{{  item.board_name }}</label>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- //게시판관리 -->

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia089'
		, props: ['cartel', 'user', 'member']
		, data: function(){
			return {
				program: {
					name: this.$language.cartel.select_under_boss_authority
					, title: this.$language.cartel.under_boss_authority
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, items_board_config: []
				, is_all: false
				, is_view: false
			}
		}
		, methods: {
			getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_manager_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.member.admin_member_number
						}
						, type: true
					})
					if(result.success){
						this.$set(this.member, 'permission', result.data)
						for(let [k, v] of Object.entries(this.member.permission)){
							console.log(k, v)
							if(k.indexOf('_fg') > -1){
								this.member.permission[k.replace('_fg', '')] = v == 'Y' ? true : false
							}
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.is_view = true
					this.$bus.$emit('on', false)
				}
			}
			,getBoardList: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.items_board_config = result.data.board_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setPermission: function(){
				let t = this.$codes.permission_manager
				let d = !this.is_all
				for(let item of t){
					this.member.permission[item] = d
				}
				for(let item of this.items_board_config){
					this.member.permission[item.code] = d
				}
			}
			, isAll: function(){
				let t = this.$codes.permission_manager

				for(let item of t){
					this.is_all = true
					if(!this.member.permission[item]){
						this.is_all = false
						break
					}
				}
			}
			, postPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.member.admin_member_number
							, cartl_all_authority_fg: this.is_all ? 'Y' : 'N'
							, cartl_admin_mangement_fg: this.member.permission.cartl_admin_mangement ? 'Y' : 'N'
							, cartl_member_mangement_fg: this.member.permission.cartl_member_mangement ? 'Y' : 'N'
							, cartl_notice_mttrs_reg_fg: this.member.permission.cartl_notice_mttrs_reg ? 'Y' : 'N'
							, cartl_board_creation_fg: this.member.permission.cartl_board_creation ? 'Y' : 'N'
							, cartl_member_grade_mangement_fg: this.member.permission.cartl_member_grade_mangement ? 'Y' : 'N'
							, cartl_subscrp_board_creation_fg: this.member.permission.cartl_subscrp_board_creation ? 'Y' : 'N'
						}
						, type: true
					})
					if(result.success){
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch(e){
					console.log('postPermission', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			console.log(this.user)

			await this.getPermission()
		}
		,watch: {
			'member.permission': {
				deep: true
				, handler: function(call){
					console.log('member.permission', call)
					let d = false
					let t = this.$codes.permission_manager
					for(let i in t){
						console.log(i, t[i], call[t[i]])
						if(!call[t[i]]){
							this.is_all = false
							d = false

							break
						}else{
							d = true
						}
					}

					if(d){
						this.is_all = true
					}
				}
			}
		}
	}
</script>